<template>
  <section>
    <div class="card">
      <div class="card-body">
        <!-- Tag filter v-if="filterType === 'tag'" -->
        <form @submit.prevent="filterTags">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <Input v-model="filter.title" id="filter_title" :label="$t('tag.filter.title')" />
            </div>
            <div class="col-lg-2 col-md-6">
              <Input v-model="filter.id" id="filter_id" :label="$t('tag.filter.id')" />
            </div>
            <div class="col-lg-4">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('tag.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
        <!-- Geneea tag filter
          <form v-if="filterType === 'geneeaTag'" @submit.prevent="filterTags">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <Input v-model="filter.title" id="filter_title" :label="$t('tag.filter.title')" />
              </div>
              <div class="col-lg-2 col-md-6">
                <Input v-model="filter.id" id="filter_id" :label="$t('tag.filter.id')" />
              </div>
            </div>
            <div class="col-lg-4">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('tag.filter.search') }}
              </button>
              <button
              type="button"
              class="btn btn-secondary m-t-30"
              data-test="reset_filter"
              @click="resetFilter"
              >
              {{ $t('buttons.reset_filter') }}
            </button>
          </div>
        </form>
      -->
      </div>
    </div>
  </section>
</template>

<script>
import Input from '@/components/form/inputs/Input'
import TagFilter from '@/model/TagFilter'
import GeneeaTagFilter from '@/model/GeneeaTagFilter'

export default {
  name: 'TagFilter',
  props: {
    filterType: {
      type: String, // tag, geneeaTag
      default: 'tag'
    }
  },
  components: {
    Input
  },
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(TagFilter),
      geneeaTagFilter: this._.cloneDeep(GeneeaTagFilter)
    }
  },
  methods: {
    filterTags () {
      this.$store.commit('tag/setPage', 1)
      this.$store.commit('tag/setFilter', this.filter)

      if (this.filterType === 'tag') {
        this.$store.dispatch('tag/fetch')
      }
      if (this.filterType === 'geneeaTag') {
        this.$store.dispatch('tag/fetchGeneeaList')
      }
    },
    resetFilter () {
      if (this.filterType === 'tag') {
        this.filter = this._.cloneDeep(TagFilter)
        this.$store.commit('tag/setFilter', this.filter)
        this.$store.dispatch('tag/fetch')
      }
      if (this.filterType === 'geneeaTag') {
        this.filter = this._.cloneDeep(GeneeaTagFilter)
        this.$store.commit('tag/setFilter', this.filter)
        this.$store.dispatch('tag/fetchGeneeaList')
      }
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['tag/filter']
  }
}
</script>
