<template>
  <div>
    <TagFilter filterType="tag" />
    <div class="card">
      <div class="card-body">
        <Preloader v-if="callingAPI" />
        <DataTable
          :data="tags"
          :totalCount="totalCount"
          :page="page"
          :config="dataTableConfig"
          :hasEditPermission="isEditButtonVisible"
          @page-change="setPageAndGetRecords"
          @deleteRecord="showDeleteModal"
        />
      </div>
    </div>
    <app-modal
      v-if="deleteModal"
      modal-size="modal-md"
      :title="$t('modal.delete_modal_header')"
      class="confirm-delete-modal"
      @close="closeDeleteModal"
    >
      <!-- Body -->
      <template slot="body">
        {{ this.$t('modal.tag_delete_modal_text') }}
      </template>

      <!-- Footer -->
      <template slot="footer">
        <button
          type="button"
          class="btn btn-inverse router-link-active"
          @click="closeDeleteModal"
        >
          {{ $t('modal.delete_modal_cancel_btn') }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          @click="deleteTag"
        >
          {{ $t('modal.delete_modal_confirm_btn') }}
        </button>
      </template>
    </app-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import TagFilter from '@/components/filter/FilterTag'
import DataTable from '@/components/shared/DataTable'
import PermissionService from '@/services/PermissionService'
import NotifyService from '@/services/NotifyService'
import appModal from '@/components/shared/Modal.vue'

export default {
  name: 'TagListView',
  data () {
    return {
      dataLoaded: false,
      deleteModal: false,
      recordFromTable: null,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_TAG_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    dataTableConfig () {
      return {
        fields: {
          id: this.$t('tag.list.id'),
          title: this.$t('tag.list.title'),
          type: this.$t('tag.geneeaTag.type')
        },
        actions: {
          detail: 'editorialTag_detail',
          edit: 'editorialTag_edit',
          delete: 'delete'
        }
      }
    },
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    tags () {
      return this.$store.getters['tag/list']
    },
    totalCount () {
      return this.$store.getters['tag/totalCount']
    },
    page () {
      return this.$store.getters['tag/page']
    }
  },
  components: {
    appModal,
    Preloader,
    TagFilter,
    DataTable
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getTags () {
      this.$store.dispatch('tag/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    deleteTag () {
      this.$store.dispatch('tag/deleteRecord', this.recordFromTable)
        .then(() => {
          if (this.$store.getters['tag/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.closeDeleteModal()
            this.getTags()
          } else {
            NotifyService.setErrorMessage(this.$store.getters['tag/error'])
            this.closeDeleteModal()
            this.getTags()
          }
        })
        .catch(error => console.log(error))
    },
    showDeleteModal (recordFromTable) {
      this.recordFromTable = recordFromTable
      this.deleteModal = true
    },
    closeDeleteModal () {
      this.deleteModal = false
    },
    setPageAndGetRecords (page) {
      this.$store.commit('tag/setPage', page)
      this.getTags()
    }
  },
  mounted () {
    this.getTags()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
